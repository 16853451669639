.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-grid-container {
  display: grid;
  grid-template-columns: 240px 1fr;
}

@media print {
  .main-grid-container {
    display: unset;
  }
  .bar .son{
    display: none;
  }
  .no-print{
    display: none !important;
  }
  .main-content {
    max-height: unset;
    height: 100%;
    overflow: unset;
  }
  .print-no-border {
    box-shadow: none !important;
  }
  .only-print {
    visibility: visible !important;
    position: relative !important;
    display: block !important;
  }
}

.main-content {
  position: relative;
  min-height: 90vh;
  overflow: auto
}

.main-header {
  padding: 12px;
}

.active-bar {
  width: 300px;
  position: fixed;
  z-index: 2;
  transition: all 0.4s ease-in-out;
  max-height: 100vh;
}

.hidden-bar {
  width: 300px;
  position: fixed;
  z-index: 2;
  transition: all 0.4s ease-in-out;
  transform: translateX(-100%);
}

.bar .son {
  position: fixed;
  min-width: 240px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.open-icon {
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg)
}

.only-print {
  visibility: hidden;
  position: absolute;
  display: none;
}

.DemoBar {
  background:tomato;
  padding: 8px 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.DemoBar > h1 {
  color: whitesmoke
}

.right {
  text-align: end;
}
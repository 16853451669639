.active::before {
  animation: bar 0.2s ease-in-out both;
}

@keyframes bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%
  }
}
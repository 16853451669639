.todayCard {
  background: white;
  text-align: left;
  border: 1px solid;
  animation: appear 0.3s ease-in-out both;
  margin: -1px;
}

@keyframes appear {
  0% {
    border-color: white;
    box-shadow: 0px 0px 0px #48A4B0;
  }
  100% {
    border-color: #48A4B0;
    box-shadow: 0px 2px 4px #48A4B0;
  }
}